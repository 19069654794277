import './App.css';
import { useState } from 'react';

function App() {
  const [deeplink, setDeeplink] = useState('');
  const onPay = () => {
    window.location.href = deeplink || "cmcb://paymentgateway?qrstring=00020101021251700020COM.CHIPMONGBANK.WWW0108100534440219R-187dfdfdcfff1854R0307GATEWAY520411115303840540420.75802KH5921Wonderpass%20Technology6010Phnom%20Penh6304F722"
  }
  const onChange = (e) => {
    setDeeplink(e.target.value)
  }
  return (
    <div className="App">
      <header className="App-header">
        <img src="raden.jpeg" style={{
          borderRadius
            : 200,
          width: 200,
          height: 200
        }} className="App-logo" alt="logo" />
        <p>Made with 💙 by Raden Hor</p>
        <input value={deeplink} onChange={onChange} placeholder='please input deep link' />
        <p>
          <button
            onClick={onPay}
          >
            Pay Now
          </button>
        </p>
      </header>
    </div>
  );
}

export default App;
